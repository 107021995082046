import Modernizr from 'modernizr';
import $ from 'jquery-slim';

class OptCarousel
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
        
        
    }

    init()
    {
        this.$carousel = $('.carousel');

        let $c;
        let $items;

        for (let i = 0; i < this.$carousel.length; i++)
        {
            $c = this.$carousel.eq(i);
            $items = $c.find('.carousel-item');
            if ($items.length < 2) $c.addClass('no-ui');
        }

        if (Modernizr.touch) this.$carousel.swipe({swipe:this.swipe.bind(this), allowPageScroll:'vertical'});
    }

    swipe(event, direction,  distance, duration, fingerCount, fingerData)
    {
        if (direction === 'left') this.$carousel.carousel('next');
        if (direction === 'right') this.$carousel.carousel('prev');
    }

}

const carousel = new OptCarousel();






